import { zodRegex } from "../@appnflat-types/schemas/zodExtensions"

const dateRegex = new RegExp(
    `^((\\d\\d[2468][048]|\\d\\d[13579][26]|\\d\\d0[48]|[02468][048]00|[13579][26]00)-02-29|\\d{4}-((0[13578]|1[02])-(0[1-9]|[12]\\d|3[01])|(0[469]|11)-(0[1-9]|[12]\\d|30)|(02)-(0[1-9]|1\\d|2[0-8])))$`
)
/** A date in the format `YYYY-MM-DD`. */
export const isoDateSchema = zodRegex<ISODate>(dateRegex)
/** A date in the format `YYYY-MM-DD`. */
export type ISODate = `${number}-${number}-${number}`

/** A date in the format `YYYY-MM`. */
export const isoYearMonthSchema = zodRegex<ISOYearMonth>(/^\d{4}-\d{2}$/)
/** A date in the format `YYYY-MM`. */
export type ISOYearMonth = `${number}-${number}`

type ISOTime = `${number}:${number}:${number}${"" | `.${number}`}`
type ISOTimeZone = "Z" | `${"+" | "-"}${number}:${number}`
/** A date in full ISO format `YYYY-MM-DDTHH:mm:SS.sssZ`.
 * @example `2022-01-01T00:00:00.000Z`
 * @example `2022-01-01T00:00:00.000+02:00`
 */
export type ISODateFull = `${ISODate}T${ISOTime}${ISOTimeZone}`

export enum DateTimePeriod {
    day,
    month,
    year,
}

export type DateTimeDiff = {
    seconds?: number
    minutes?: number
    hours?: number
    days?: number
    weeks?: number
    months?: number
    years?: number
}

/** Pads a number with a zero if it's less than 10. */
function pad(num: number) {
    return String(num).padStart(2, "0")
}

export class DateTime {
    /** The UNIX epoch of the date, in UTC timezone. */
    protected readonly ms: number

    /** Constructs a new DateTime.
     *
     * @param date
     *  - a UNIX epoch in seconds in UTC,
     *  - a string in the format `YYYY-MM` (will be converted to the start of the month),
     *  - a string in the format `YYYY-MM-DD` (will be converted to the start of the day),
     *  - a string in the format `YYYY-MM-DDTHH:MM:SS.sssZ` (`sss` is optional),
     *  - a JS Date object (timezone will be ignored and treated as UTC),
     *  - "now" for the current date in the UTC timezone.
     */
    constructor(date: number | Date | "now" | ISOYearMonth | ISODate | ISODateFull) {
        if (date === "now") {
            const now = new Date()
            this.ms = Date.UTC(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours(),
                now.getUTCMinutes(),
                now.getUTCSeconds(),
                now.getUTCMilliseconds()
            )
        } else if (date instanceof Date) {
            this.ms = Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds()
            )
        } else if (typeof date === "string") {
            let dateStr = date
            if (!date.includes("T")) {
                if (/^\d+-\d\d-\d\d$/.test(date)) {
                    dateStr += "T00:00:00.000Z"
                } else if (/^\d+-\d\d$/.test(date)) {
                    dateStr += "-01T00:00:00.000Z"
                } else {
                    throw new TypeError(`Invalid string passed to DateTime constructor: ${date}`)
                }
            }
            this.ms = Date.parse(dateStr)
        } else {
            this.ms = date * 1000
        }
    }

    /** Returns the start of the set period. */
    public startOf(start: DateTimePeriod) {
        const convertedDate = new Date(this.ms)
        let dateString = `${convertedDate.getUTCFullYear()}-`
        const month = String(convertedDate.getUTCMonth() + 1).padStart(2, "0")
        switch (start) {
            case DateTimePeriod.day:
                dateString += `${month}-${String(convertedDate.getUTCDate()).padStart(2, "0")}`
                break
            case DateTimePeriod.month:
                dateString += `${month}-01`
                break
            case DateTimePeriod.year:
                dateString += `01-01`
                break
            default:
                throw new Error(`Invalid DateTimePeriod passed to 'startOf': ${start}`)
        }
        return new DateTime(dateString as ISODate)
    }

    /** Returns the end of the set period. */
    public endOf(end: DateTimePeriod) {
        const date = new Date(this.ms)
        const year = date.getUTCFullYear()
        switch (end) {
            case DateTimePeriod.day:
                return new DateTime(
                    Date.UTC(year, date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999) / 1000
                )
            case DateTimePeriod.month:
                return new DateTime(
                    Date.UTC(year, date.getUTCMonth() + 1, 0, 23, 59, 59, 999) / 1000
                )
            case DateTimePeriod.year:
                return new DateTime(Date.UTC(year, 11, 31, 23, 59, 59, 999) / 1000)
            default:
                throw new Error(`Invalid DateTimePeriod passed to 'endOf': ${end}`)
        }
    }

    public addYears(years: number) {
        return new DateTime(
            `${this.year + years}-${pad(this.month)}-${pad(this.day)}T${pad(this.hour)}:${pad(this.minute)}:${pad(this.second)}Z` as ISODateFull
        )
    }

    public addMonths(months: number) {
        let year = this.year
        let month = this.month
        const isAdd = months > 0
        for (let i = 0; i < Math.abs(months); i++) {
            if (isAdd) {
                month++
                if (month > 12) {
                    month = 1
                    year++
                }
            } else {
                month--
                if (month < 1) {
                    month = 12
                    year--
                }
            }
        }
        return new DateTime(
            `${year}-${pad(month)}-${pad(this.day)}T${pad(this.hour)}:${pad(this.minute)}:${pad(this.second)}Z` as ISODateFull
        )
    }

    public addDays(days: number) {
        const newDate = new Date(this.ms)
        newDate.setUTCDate(newDate.getUTCDate() + days)
        const year = newDate.getUTCFullYear()
        const month = pad(newDate.getUTCMonth() + 1)
        const day = pad(newDate.getUTCDate())
        return new DateTime(
            `${year}-${month}-${day}T${pad(this.hour)}:${pad(this.minute)}:${pad(this.second)}Z` as ISODateFull
        )
    }

    public addSeconds(seconds: number) {
        return new DateTime(this.toSeconds() + seconds)
    }

    /** Adds the specified amount of time to the DateTime. */
    public plus(amount: DateTimeDiff) {
        return this.addYears(amount.years ?? 0)
            .addMonths(amount.months ?? 0)
            .addDays((amount.days ?? 0) + (amount.weeks ?? 0) * 7)
            .addSeconds(
                (amount.hours ?? 0) * 3600 + (amount.minutes ?? 0) * 60 + (amount.seconds ?? 0)
            )
    }

    /** Converts the DateTime to a JS Date. */
    public toDate() {
        return new Date(this.ms)
    }

    /** Converts the DateTime to a JS Date, modifying the timestamp to ensure
     * that the date and time are the same in the local time zone as they were
     * in the UTC time zone.
     *
     * @example If the date is `2022-01-01 00:00:00 UTC`, it will be converted
     * to `2022-01-01 00:00:00 UTC+2` if the local time zone is UTC+2.
     */
    public toLocalDate() {
        console.log("DATE:", {
            params: [this.year, this.month - 1, this.day, this.hour, this.minute, this.second],
            i2: new Date(this.year, this.month - 1, this.day, this.hour, this.minute, this.second),
            i1: new Date(this.ms + new Date(this.ms).getTimezoneOffset() * 60 * 1000),
            o: new Date(this.ms + new Date().getTimezoneOffset() * 60 * 1000),
            tz1: new Date(this.ms).getTimezoneOffset(),
            tz0: new Date().getTimezoneOffset(),
        })
        // Alternative implementation 2
        // return new Date(this.year, this.month - 1, this.day, this.hour, this.minute, this.second)

        // Alternative implementation 1
        return new Date(this.ms + new Date(this.ms).getTimezoneOffset() * 60 * 1000)

        // Original implementation: doesn't handle DST correctly.
        // return new Date(this.ms + new Date().getTimezoneOffset() * 60 * 1000)
    }

    /** Returns the UNIX epoch in seconds, as an integer. */
    public toSeconds() {
        return Math.floor(this.ms / 1000)
    }

    // /** WARNING: `toSeconds()` should be used most of the time. */
    // public toSecondsInLocalTZ() {
    //     return Math.floor(this.ms / 1000) + new Date().getTimezoneOffset() * 60
    // }

    /** Returns the date in the format `YYYY-MM-DDTHH:mm:SS.sssZ`. */
    public toISOFull(): ISODateFull {
        return this.toDate().toISOString() as ISODateFull
    }

    /** Returns the date in the format `YYYY-MM-DD`. */
    public toISO(): ISODate {
        const matched = this.toDate()
            .toISOString()
            .match(/^\d+-\d\d-\d\d/)?.[0]
        if (!matched) throw new Error("Failed to convert date to ISO format.")
        return matched as ISODate
    }

    /** Returns the date in the format `YYYY-MM`. */
    public toISOMonth(): ISOYearMonth {
        const matched = this.toDate()
            .toISOString()
            .match(/^\d+-\d\d/)?.[0]
        if (!matched) throw new Error("Failed to convert date to ISO month format.")
        return matched as `${number}-${number}`
    }

    /** Returns the current year.
     * @example `2022-01-01` -> 2022
     */
    get year() {
        return this.toDate().getUTCFullYear()
    }
    /** Returns the current month (1-12).
     * @example `2022-03-01` -> 3
     */
    get month() {
        return this.toDate().getUTCMonth() + 1
    }
    /** Returns the current day (1-31).
     * @example `2022-01-01` -> 1
     */
    get day() {
        return this.toDate().getUTCDate()
    }
    /** Returns the current day of the week (0-6).
     * @example Sunday -> 0.
     * @example Monday -> 1.
     */
    get weekday() {
        return this.toDate().getUTCDay()
    }
    get hour() {
        return this.toDate().getUTCHours()
    }
    get minute() {
        return this.toDate().getUTCMinutes()
    }
    get second() {
        return this.toDate().getUTCSeconds()
    }

    valueOf = this.toSeconds
    toString = this.toISO
}

export class Interval {
    public readonly start: DateTime
    public readonly end: DateTime
    public readonly diff: DateTimeDiff

    constructor(start: DateTime, end: DateTime, diff: DateTimeDiff) {
        this.start = start
        this.end = end
        this.diff = diff
    }

    public getDaysAsDateTimes() {
        let localStart = this.start
        const days: DateTime[] = []
        while (localStart <= this.end) {
            days.push(localStart)
            localStart = localStart.plus(this.diff)
        }
        return days
    }

    public getDaysAsSeconds() {
        return this.getDaysAsDateTimes().map((day) => day.toSeconds())
    }

    public getDaysAsISODates() {
        return this.getDaysAsDateTimes().map((day) => day.toISO())
    }
}
