import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import { cachedBuildingSelector, cachedUserInBuildingSelector } from "./cache"
import { TKey } from "i18n"
import { Role } from "@appnflat-types/Role"
import * as Sentry from "@sentry/react"

export function loadingSelector(state: RootState) {
    return state.app.loading
}

export function userSelector(state: RootState) {
    return state.app.user
}
export function userUIDSelector(state: RootState) {
    return state.app.user?.uid
}
export function currencySelector(state: RootState) {
    return cachedBuildingSelector(state)?.currency ?? "CAD"
}
export function userRoleSelector(state: RootState) {
    return cachedUserInBuildingSelector(state)?.role
}
export function userBannedFromSocialSelector(state: RootState) {
    const user = cachedUserInBuildingSelector(state)
    return !!(user && user.role && [Role.owner, Role.resident].includes(user.role) && user.banned)
}
export function allFiscalYearsSelector(state: RootState) {
    return cachedBuildingSelector(state)?.fiscalYears
}
export const buildingRefSelector = (state: RootState) => state.app.buildingRef
export const fiscalYearSelector = (state: RootState) => state.app.fiscalYear
export const isArchivedFiscalYearSelector = (state: RootState) => {
    if (!state.app.fiscalYear) return false
    return !cachedBuildingSelector(state)?.unarchivedFiscalYears?.includes(state.app.fiscalYear)
}

export type AppState = {
    buildingRef?: string
    fiscalYear?: number
    user?: {
        email?: string
        name?: string
        uid?: string
    }
    loading: {
        show: boolean
        message?: TKey
        icon?: "cloud-lock" | "report" | "fiscal-year" | "stripe"
    }
}
const initialState: AppState = {
    buildingRef: undefined,
    fiscalYear: undefined,
    loading: { show: false },
}

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLoading: function _setLoading(
            state,
            action: PayloadAction<
                boolean | TKey | { message: TKey; icon: AppState["loading"]["icon"] }
            >
        ) {
            if (typeof action.payload === "boolean") state.loading = { show: action.payload }
            else if (action.payload === "") state.loading = { show: false }
            else if (typeof action.payload === "string")
                state.loading = { show: true, message: action.payload }
            else state.loading = { show: true, ...action.payload }
        },

        emptyAppState: () => initialState,

        setUser: function _setUser(state, action: PayloadAction<AppState["user"]>) {
            state.user = action.payload
            if (action.payload) {
                Sentry.setUser({ id: action.payload.uid, email: action.payload.email })
            } else {
                Sentry.setUser(null)
            }
        },

        setCurrentBuilding: function _setCurrentBuilding(
            state,
            action: PayloadAction<string | undefined>
        ) {
            state.buildingRef = action.payload
            Sentry.setTag("buildingRef", action.payload ?? "ABSENT")
        },

        setFiscalYear: function _setFiscalYear(state, action: PayloadAction<number | undefined>) {
            state.fiscalYear = action.payload
        },
    },
})

export const { setUser, setLoading, emptyAppState, setCurrentBuilding, setFiscalYear } =
    appSlice.actions
