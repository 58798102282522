import { z } from "zod"
import { accountSchema, dineroStorableSchema, amountsSummarySchema } from "./Common"
import { mediumString, shortMarkdownString, uuidString, veryShortString } from "./BaseStrings"
import { isoYearMonthSchema } from "../../@shared/dates"

/** The schema for a bank account type. */
export const bankAccountTypeSchema = z.enum(["saving", "checking", "investment"])
/** The type of bank account. */
export type BankAccountType = z.infer<typeof bankAccountTypeSchema>

export const bankSchema = accountSchema.extend({
    startingBalanceReconciled: dineroStorableSchema.optional(),
    /** The list of months that were reconciled. Values are in yyyy-LL format. */
    reconciledMonths: z.array(isoYearMonthSchema).optional(),
    /** This is the actual balance at a given month as entered by the user, multiplied by -1.
     * E.g., if the bank's statement indicates there is 500$ left in the account at the end of the month,
     * the user will enter 500 and we will store -500. This is as bank accounts in the app are operating
     * accounts, which are the opposite of the bank account.
     * The keys are of the format yyyy-LL. */
    enteredReconciliations: amountsSummarySchema.optional(),
    /** The number of the bank account. For display purposes only. */
    number: veryShortString.optional(),
    name: mediumString.optional(),
    /** The uuid of the supplier of the bank (to which late fees, etc. are paid). */
    supplierAccount: uuidString.optional(),
    notes: shortMarkdownString.optional(),
    /** What kind of bank account this is. */
    bankAccountType: bankAccountTypeSchema.optional(),
})
export type Bank = z.infer<typeof bankSchema>
