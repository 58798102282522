import { z } from "zod"
import { emailString, mediumString, uuidString } from "./BaseStrings"

const emailAddressAndNameSchema = z.object({
    /** The email address. */
    address: emailString,
    /** The name part of the email/group. */
    name: z.string().optional(),
})
export type EmailAddressAndName = z.infer<typeof emailAddressAndNameSchema>

/**
 * A request email.
 *
 * Corresponds to the document `db/buildings/{buildingRef}/requestEmails/{uuid}`. To get the
 * email itself, fetch it from the `bucket/buildings/{buildingRef}/requestEmails/{uuid}/email.eml`
 * object.
 */
export const requestEmailDocumentSchema = z.object({
    uuid: uuidString,
    /** The uuid of the request.
     * If it is not associated to a request, the value should be `"unknown"`.
     * If it was not associated to a request and trashed, the value should be "trashed". */
    requestUUID: z.union([
        uuidString,
        z.literal("unknown"),
        z.literal("trashed"),
        z.literal("spam"),
    ]),
    date: z.number(),
    preview: z.object({
        /** The first 80 characters of the email subject. */
        subject: mediumString,
        /** The first 80 characters of the email body. */
        body: mediumString,
    }),
    /** Attached files.
     *
     * Files are stored as `bucket/buildings/{buildingRef}/requests/{requestUUID}/{fileID}`.
     */
    attachments: z
        .array(
            z.object({
                /** The name to display to the user as the file name. */
                fileDisplayName: z.string().max(100),
                /**
                 * The id of the file, in the format `{uuid}.{extension}` (`extension` being one of
                 * `pdf`, `webp`, etc.) that is the name of a file.
                 */
                fileID: z.string(),
            })
        )
        .optional(),
    /** The author of the email. "self" if the author is the user, an email otherwise. */
    from: z.union([emailAddressAndNameSchema, z.literal("self")]),
    /** The uid of the user who sent the email. */
    internalSentBy: z.string().optional(),
    /** The recipients of the email. Currently limited to a maximum of 20 recipients. */
    recipients: z.array(emailAddressAndNameSchema).max(20),
    /** The uid of the first person to read the email or false if unread or "self" if sent by a user of the app. */
    read: z.union([z.string(), z.literal(false), z.literal("self")]),
    /** The MessageID. */
    messageId: z.string().optional(),
    /** The messageID of the email this is in reply to (i.e., the In-Reply-To header). */
    inReplyTo: z.string().optional(),
    /** The References header (a list of messageIDs). */
    references: z.array(z.string()).optional(),
})
/**
 * A request email.
 * Corresponds to the document `db/buildings/{buildingRef}/requestEmails/{uuid}`. To get the
 * email itself, fetch it from the `bucket/buildings/{buildingRef}/requestEmails/{uuid}/` object.
 */
export type RequestEmailDocument = z.infer<typeof requestEmailDocumentSchema>
