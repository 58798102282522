import { z } from "zod"
import { Role } from "../Role"
import { emailString, mediumString, uidString } from "./BaseStrings"

export const userSchema = z.object({
    name: mediumString.optional(),
    uid: uidString,
    role: z.nativeEnum(Role).optional(),
    banned: z.boolean().optional(),
    email: emailString.optional(),
})
export type User = z.infer<typeof userSchema>
