import { z } from "zod"
import { objectWithAddressSchema } from "./Address"
import { emailFrequencySchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"
import { currencySchema } from "./Currency"
import {
    aidString,
    buildingRefString,
    emailString,
    mediumString,
    phoneString,
    shortString,
    uuidString,
} from "./BaseStrings"
import { localeSchema } from "./Language"

export const buildingSchema = objectWithAddressSchema.extend({
    name: mediumString.optional(),
    /** @deprecated Use `buildingRef` field instead. */
    uuid: uuidString,
    buildingRef: buildingRefString,
    /**
     * The email at which the building can be contacted.
     *
     * This is not verified (i.e., only use it for display purposes.)
     * This is NOT the same as `requestsEmail`, i.e., it is not an `@buildings.appnflat.com` email.
     */
    email: emailString.email({ message: ParsingErrors.invalid_email_address }).optional(),
    /**
     * The phone number at which the building can be contacted.
     *
     * This is not verified (i.e., only use it for display purposes.)
     */
    phone: phoneString.optional(),
    /** The time zone of the building. Not editable. */
    timeZone: shortString.optional(),
    /** The currency of the building. Not editable. */
    currency: currencySchema,
    /**
     * The default locale of the building.
     * (note that this includes a region, e.g., `en-CA` or `fr-CA`).
     *
     * Used for communications and automatically created transactions descriptions.
     * @deprecated Use `locale` field instead.
     */
    language: localeSchema.optional(),
    /**
     * The default locale of the building.
     *
     * Used for communications and automatically created transactions descriptions.
     */
    locale: localeSchema.optional(),
    /** A list of all the fiscal years in the building. */
    fiscalYears: z.array(z.number()),
    /** A list of the fiscal years in the building that can still be edited. */
    unarchivedFiscalYears: z.array(z.number()),
    /** The bank account to which unit payments go, as well from which Otonom payments are done. */
    defaultBankAccountAID: aidString.optional(),
    /** A list of all the floors in the building. */
    floors: z.array(z.string()).optional(),
    /** Minimum amount a unit needs to have as an unpaid balance before we start charging penalties or interests. */
    minimumUnitBalanceBeforeChargingPenalties: z.number().gte(0).optional(),
    /** Whether we should send an email to the owners of a unit when a new check for that unit is saved. */
    sendEmailOnCheckSaved: z.boolean().optional(),
    /** The frequency with which to send an email to units with a missing insurance. */
    emailMissingDataInsuranceFrequency: emailFrequencySchema.optional(),
    /** The frequency with which to send an email to units with an insurance that is about to expire. */
    emailAboutToExpireInsuranceFrequency: emailFrequencySchema.optional(),
    /** How much time in advance to start emailing units about an insurance that is about to expire (in seconds). */
    emailAboutToExpireInsuranceStart: z.number().positive().optional(),
    /** The frequency with which to send an email to units with a missing water heater. */
    emailMissingDataWaterHeaterFrequency: emailFrequencySchema.optional(),
    /** The frequency with which to send an email to units with a water heater that is about to expire. */
    emailAboutToExpireWaterHeaterFrequency: emailFrequencySchema.optional(),
    /** How much time in advance to start emailing units about a water heater that is about to expire (in seconds). */
    emailAboutToExpireWaterHeaterStart: z.number().optional(),
    /** Whether to send emails to units with an unpaid balance. */
    emailUnpaidBalanceUnits: z.boolean().optional(),
    /** Whether to enable the Otonom integration. */
    integrateOtonom: z.boolean().optional(),
    /** Number of months after which unit is highlighted for not paying. */
    numberOfMonthsUnitHasUnpaidBalanceBeforeAlert: z.number().positive().optional(),
    /** Indicates that a building is used for tests. Should NOT be true for any non-internal buildings. */
    isAlpha: z.boolean().optional(),

    /** Payment information */
    paidUntil: z.number().optional(),
    freeTrialUntil: z.number().optional(),
    /** If true, no emails should be sent to any resident, owner, etc. of this building. */
    sendNoEmails: z.boolean().optional(),

    stripeCustomerId: z.string().max(80).optional(),

    /** Whether owners are allowed to see all financial information. */
    ownersCanAccessFinances: z.boolean().optional(),

    /** A balance below which we will warn the user of a low balance on the bank account before making a payment. */
    lowBalanceWarningThreshold: z.number().gte(0).optional(),

    /** The email used to create new requests for the building. It is an `@buildings.appnflat.com` email. */
    outgoingEmailAddress: emailString.optional(),

    /** The maximum amount of storage that can be used by the building, in bytes. */
    storageSizeLimit: z.number().optional(),
})
export type Building = z.infer<typeof buildingSchema>
