import { z } from "zod"
import {
    AttachmentUploadedSchema,
    objectWithMonthlyFeesSchema,
    objectWithEncryptedBankAccountDetailsSchema,
    dineroStorableSchema,
    accountSchema,
} from "./Common"
import {
    accountWithRecurringBillsSchema,
    accountWithRecurringBillsValidator,
    accountWithoutRecurringBillsSchema,
} from "./RecurringBill"
import { ParsingErrors } from "./parsingErrors"
import {
    emailString,
    mediumString,
    shortMarkdownString,
    shortString,
    uuidString,
    veryShortString,
} from "./BaseStrings"
import { zodRegex } from "./zodExtensions"

export const waterHeaterSchema = z.object({
    installationDate: z.number().optional(),
    endOfWaranty: z.number().optional(),
    location: z.boolean().optional(),
    attachmentUploaded: AttachmentUploadedSchema,
})
export type WaterHeater = z.infer<typeof waterHeaterSchema>

export const insuranceSchema = z.object({
    endDate: z.number().optional(),
    insuranceProviderName: mediumString.optional(),
    policyNumber: shortString.optional(),
    civilResponsabilityAmount: z.number().optional(),
    attachmentUploaded: AttachmentUploadedSchema,
})
export type insurance = z.infer<typeof insuranceSchema>

export const unitNumberSchema = zodRegex<`${number}`>(/(^\d+$)|(^\d+\.\d+$)/, {
    message: ParsingErrors.should_be_decimal_positive_number,
})
// z.string().regex(/(^\d+$)|(^\d+\.\d+$)/, {
//     message: ParsingErrors.should_be_decimal_positive_number,
// })

/** A unit without recurringBills.
 * WARNING: this should almost never be used! Use `unitSchema` instead.
 */
export const baseUnitSchema = accountSchema
    .merge(objectWithMonthlyFeesSchema)
    .merge(objectWithEncryptedBankAccountDetailsSchema)
    .extend({
        number: unitNumberSchema,
        floor: veryShortString.optional(),
        /** The uuids of the owners of the unit. */
        owners: z.array(uuidString),
        /** The uuids of the residents of the unit. */
        residents: z.array(uuidString).optional(),
        /** Whether the unit pays automatically. Only be true if paymentDay is set. */
        automaticPayment: z.boolean().optional(),
        paymentDay: z.number().min(1).max(31).optional(),
        voteShare: z
            .number()
            .min(0, { message: ParsingErrors.must_be_positive })
            .max(1, { message: ParsingErrors.must_be_less_than_100 }),
        parkings: z.array(uuidString).max(29).optional(),
        lockers: z.array(uuidString).max(29).optional(),
        notes: shortMarkdownString.optional(),
        soldAndNeedToSetBalanceToZero: z.boolean().optional(),
        /** Used to check if an owner has access to the unit. */
        ownersEmails: z.array(emailString).optional(),
        /** The number of months the unit has has an unpaid balance. */
        numberOfMonthsWithUnpaidBalance: z.number().optional(),
        /** The timestamp of the first time we saw an unpaid balance for the unit. */
        firstTimeUnpaidBalanceSeen: z.number().optional(),
        /** The details for the unit's water heater. */
        waterHeater: waterHeaterSchema.optional(),
        insurance: insuranceSchema.optional(),
        /**
         * An object mapping the uuid of the owner to the date they started owning the unit.
         *
         * @example { "uuid1": 1234567890, "uuid2": 1234567890 } */
        ownershipDates: z.record(uuidString, z.number()).optional(),
        /**
         * An object mapping the uuid of the resident to the date they started residing in the unit.
         *
         * @example { "uuid1": 1234567890, "uuid2": 1234567890 } */
        residencyDates: z.record(uuidString, z.number()).optional(),
        /**
         * A map of the uuid of the owner to the name of the owner.
         *
         * Used to display the names of owners to users without access to financials.
         * @example { "uuid1": "John Doe", "uuid2": "Jane Doe" }
         */
        ownersNames: z.record(uuidString, mediumString).optional(),
        /**
         * A map of the uuid of the resident to the name of the resident.
         *
         * Used to display the names of residents to users without access to financials.
         * @example { "uuid1": "John Doe", "uuid2": "Jane Doe" }
         */
        residentsNames: z.record(uuidString, mediumString).optional(),
        /**
         * The balance of the unit at the start of the fiscal year, only considering
         * transactions used to calculate if a unit should receive a penalty.
         *
         * This only includes transactions that are used to calculate penalties:
         * payments (and credit notes) and monthly fees.
         */
        startingBalanceForPenalties: dineroStorableSchema.optional(),
    })

export const unitSchema = z
    .union([
        baseUnitSchema.merge(accountWithRecurringBillsSchema),
        baseUnitSchema.merge(accountWithoutRecurringBillsSchema),
    ])
    .refine(accountWithRecurringBillsValidator)

export type Unit = z.infer<typeof unitSchema>
