import { OptionalWithRequiredButPossiblyUndefined } from "../@appnflat-types/helpers"
import { Bank } from "../@appnflat-types/schemas/Bank"
import { Budget } from "../@appnflat-types/schemas/Budget"
import { Building } from "../@appnflat-types/schemas/Building"
import { Category } from "../@appnflat-types/schemas/Category"
import { Check } from "../@appnflat-types/schemas/Check"
import { EmailTemplate } from "../@appnflat-types/schemas/EmailTemplate"
import { InvitedUser } from "../@appnflat-types/schemas/InvitedUser"
import { Locker } from "../@appnflat-types/schemas/Locker"
import { OtonomBatch } from "../@appnflat-types/schemas/OtonomBatch"
import { Parking } from "../@appnflat-types/schemas/Parking"
import { Penalty } from "../@appnflat-types/schemas/Penalty"
import { Person } from "../@appnflat-types/schemas/Person"
import { Post } from "../@appnflat-types/schemas/Post"
import { PreparedOtonomFile } from "../@appnflat-types/schemas/PreparedOtonomFile"
import { Supplier } from "../@appnflat-types/schemas/Supplier"
import { Transaction } from "../@appnflat-types/schemas/Transaction"
import { Unit } from "../@appnflat-types/schemas/Unit"
import { User } from "../@appnflat-types/schemas/User"
import { MetadataDocuments } from "../@appnflat-types/FirestoreCollections"
import { Request, RequestTag } from "../@appnflat-types/schemas/Request"
import { RequestEmailDocument } from "../@appnflat-types/schemas/RequestEmail"
import { NotificationSent } from "../@appnflat-types/schemas/Notifications"
import { Log } from "../@appnflat-types/schemas/Log"

export type IdForCollectionInput =
    | {
          collection: "units"
          value: OptionalWithRequiredButPossiblyUndefined<Unit, "uuid" | "fiscalYear">
      }
    | {
          collection: "suppliers"
          value: OptionalWithRequiredButPossiblyUndefined<Supplier, "uuid" | "fiscalYear">
      }
    | {
          collection: "banks"
          value: OptionalWithRequiredButPossiblyUndefined<Bank, "uuid" | "fiscalYear">
      }
    | {
          collection: "people"
          value: OptionalWithRequiredButPossiblyUndefined<Person, "uuid" | "fiscalYear">
      }
    | {
          collection: "categories"
          value: OptionalWithRequiredButPossiblyUndefined<Category, "uuid" | "fiscalYear">
      }
    | {
          collection: "parkings"
          value: OptionalWithRequiredButPossiblyUndefined<Parking, "uuid" | "fiscalYear">
      }
    | {
          collection: "lockers"
          value: OptionalWithRequiredButPossiblyUndefined<Locker, "uuid" | "fiscalYear">
      }
    | {
          collection: "transactions"
          value: OptionalWithRequiredButPossiblyUndefined<Transaction, "uuid" | "fiscalYear">
      }
    | {
          collection: "unreconciledTransactions"
          value: OptionalWithRequiredButPossiblyUndefined<Transaction, "uuid" | "fiscalYear">
      }
    | {
          collection: "otonomBatches"
          value: OptionalWithRequiredButPossiblyUndefined<OtonomBatch, "uuid">
      }
    | {
          collection: "posts"
          value: OptionalWithRequiredButPossiblyUndefined<Post, "uuid">
      }
    | {
          collection: "penalties"
          value: OptionalWithRequiredButPossiblyUndefined<Penalty, "uuid">
      }
    | {
          collection: "checks"
          value: OptionalWithRequiredButPossiblyUndefined<Check, "uuid">
      }
    | {
          collection: "requests"
          value: OptionalWithRequiredButPossiblyUndefined<Request, "uuid">
      }
    | {
          collection: "requestEmails"
          value: OptionalWithRequiredButPossiblyUndefined<RequestEmailDocument, "uuid">
      }
    | {
          collection: "requestTags"
          value: OptionalWithRequiredButPossiblyUndefined<RequestTag, "uuid">
      }
    | {
          collection: "budgets"
          value: OptionalWithRequiredButPossiblyUndefined<Budget, "uuid">
      }
    | {
          collection: "preparedOtonomFiles"
          value: OptionalWithRequiredButPossiblyUndefined<PreparedOtonomFile, "kind" | "fiscalYear">
      }
    | {
          collection: "preparedOtonomFiles"
          value: OptionalWithRequiredButPossiblyUndefined<
              PreparedOtonomFile,
              "kind" | "fiscalYear" | "supplierUUID"
          >
      }
    | {
          collection: "buildings"
          value: OptionalWithRequiredButPossiblyUndefined<Building, "buildingRef">
      }
    | {
          collection: "users"
          value: OptionalWithRequiredButPossiblyUndefined<User, "uid">
      }
    | {
          collection: "invitedUsers"
          value: OptionalWithRequiredButPossiblyUndefined<InvitedUser, "email">
      }
    | {
          collection: "emailTemplates"
          value: OptionalWithRequiredButPossiblyUndefined<EmailTemplate, "kind">
      }
    | {
          collection: "secretsBankAccounts"
          value: { aid: string }
      }
    | {
          collection: "metadata"
          value: { id: MetadataDocuments }
      }
    | {
          collection: "notificationsSent"
          value: OptionalWithRequiredButPossiblyUndefined<NotificationSent, "notificationId">
      }
    | {
          collection: "logs"
          value: OptionalWithRequiredButPossiblyUndefined<Log, "uuid">
      }

/** Returns the id for a collection. */
export function idForCollection({ collection, value }: IdForCollectionInput): string | undefined {
    if (!value) return undefined
    switch (collection) {
        case "metadata":
            return value.id
        case "users":
            return value.uid
        case "invitedUsers":
            return value.email
        case "emailTemplates":
            return value.kind
        case "buildings":
            return value.buildingRef
        case "otonomBatches":
        case "posts":
        case "penalties":
        case "checks":
        case "requests":
        case "requestEmails":
        case "requestTags":
        case "budgets":
        case "logs":
            return value.uuid
        case "preparedOtonomFiles":
            return value.kind === "units"
                ? "all-units"
                : `supplier-${value.supplierUUID}#${value.fiscalYear}`
        case "secretsBankAccounts":
            return value.aid
        // case "verificationCodes":
        //     return value.email
        case "notificationsSent": {
            const id = value.notificationId
            if (!id) return undefined
            switch (id.type) {
                case "requestEmail":
                    return `${id.requestEmailUUID}#${id.type}`
                case "unpaidBalance":
                case "insuranceExpiration":
                case "insuranceInfoMissing":
                case "waterHeaterExpiration":
                case "waterHeaterInfoMissing": {
                    const date = id.date.slice(
                        0,
                        id.frequency === "yearly" ? 4 : id.frequency === "monthly" ? 7 : 10
                    )
                    return `${id.unitUUID}#${id.type}#${date}`
                }
                case "notifyRecipientsOfPost":
                    return `${id.postUUID}#${id.type}`
                case "pendingTransactionApproval":
                    return `${id.transactionUUID}#${id.type}`
                case "newCheckSavedForUnit":
                    return `${id.unitUUID}#${id.checkUUID}#${id.type}`
                case "emailVerificationCode":
                    return `${id.uuid}#${id.type}`
                case "userAddedToBuilding":
                    return `${id.userUID}#${id.type}`
                case "userInvitedToBuilding":
                    return `${id.uuid}#${id.type}`
                case "unitStatement":
                    return `${id.unitUUID}#${id.day}#${id.type}`
                default: {
                    const exhaustiveCheck: never = id
                    throw new Error(`Unhandled notification type: ${exhaustiveCheck}`)
                }
            }
        }
        default:
            if (!value.uuid || !value.fiscalYear) return undefined
            return `${value.uuid}#${value.fiscalYear}`
    }
}
