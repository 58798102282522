/* Core Mantine styles. To be imported before all others. */
import "@mantine/core/styles/global.layer.css"

/* Styles used by other components. */
import "@mantine/core/styles/ScrollArea.layer.css"
import "@mantine/core/styles/UnstyledButton.layer.css"
import "@mantine/core/styles/VisuallyHidden.layer.css"
import "@mantine/core/styles/Paper.layer.css"
import "@mantine/core/styles/Popover.layer.css"
import "@mantine/core/styles/CloseButton.layer.css"
import "@mantine/core/styles/Group.layer.css"
import "@mantine/core/styles/Loader.layer.css"
import "@mantine/core/styles/Overlay.layer.css"
import "@mantine/core/styles/Input.layer.css"
import "@mantine/core/styles/Flex.layer.css"

/* Styles used in both Static and App. */
import "@mantine/core/styles/Anchor.layer.css"
import "@mantine/core/styles/Button.layer.css"
import "@mantine/core/styles/Card.layer.css"
import "@mantine/core/styles/Container.layer.css"
import "@mantine/core/styles/LoadingOverlay.layer.css"
import "@mantine/core/styles/NavLink.layer.css"
import "@mantine/core/styles/PasswordInput.layer.css"
import "@mantine/core/styles/SimpleGrid.layer.css"
import "@mantine/core/styles/Stack.layer.css"
import "@mantine/core/styles/Text.layer.css"
import "@mantine/core/styles/Title.layer.css"

import { createTheme, MantineColorsTuple } from "@mantine/core"
import * as inputClasses from "./css/mantine/Input.module.css"
import * as inputWrapperClasses from "./css/mantine/InputWrapper.module.css"
import * as alertClasses from "./css/mantine/Alert.module.css"
import * as checkboxClasses from "./css/mantine/Checkbox.module.css"
import * as buttonClasses from "./css/mantine/Button.module.css"
import * as unstyledButtonClasses from "./css/mantine/UnstyledButton.module.css"
import * as badgeClasses from "./css/mantine/Badge.module.css"
import * as multiSelectClasses from "./css/mantine/MultiSelect.module.css"
import * as textClasses from "./css/mantine/Text.module.css"
import * as dividerClasses from "./css/mantine/Divider.module.css"
import * as groupClasses from "./css/mantine/Group.module.css"
import * as colorPickerClasses from "./css/mantine/ColorPicker.module.css"
import * as pillClasses from "./css/mantine/Pill.module.css"

const primary: MantineColorsTuple = [
    "#ebefff",
    "#d2dbff",
    "#a4b3f8",
    "#738af1",
    "#4a66eb",
    "#2f4fe8",
    "#2044e7",
    "#1136ce",
    "#052fb9",
    "#0029a4",
]

const labelText: MantineColorsTuple = [
    "#f3f3ff",
    "#e1e6ef",
    "#c6cbd6",
    "#a7afbc",
    "#8e96a6",
    "#7d8799",
    "#747f94",
    "#636d81",
    "#566176",
    "#46536a",
]

export const theme = createTheme({
    respectReducedMotion: true,
    primaryColor: "primary",
    scale: 1.14,
    headings: {
        fontWeight: "400",
        sizes: {
            h1: { fontSize: "22px", lineHeight: "1.4" },
            h2: { fontSize: "18px", lineHeight: "1.5" },
        },
    },
    fontSizes: {
        xs: "10px",
        sm: "12px",
        md: "14px",
        lg: "16px",
        xl: "20px",
    },
    lineHeights: {
        xs: "1.3",
        sm: "1.4",
        md: "1.45",
        lg: "1.5",
        xl: "1.65",
    },
    spacing: {
        sm: "3px",
    },
    colors: { primary, labelText },
    components: {
        ActionIcon: { defaultProps: { variant: "transparent" } },
        Alert: {
            classNames: alertClasses,
            defaultProps: {
                variant: "outline",
            },
        },
        Badge: {
            classNames: badgeClasses,
            defaultProps: {
                size: "xs",
                radius: "md",
            },
        },
        Button: {
            classNames: buttonClasses,
            defaultProps: {
                size: "compact-md",
                variant: "outline",
                color: "var(--mantine-color-text)",
            },
        },
        Checkbox: { classNames: checkboxClasses },
        ColorPicker: { classNames: colorPickerClasses },
        Divider: {
            classNames: dividerClasses,
            defaultProps: {
                labelPosition: "left",
                color: "dark.1",
            },
        },
        Group: { classNames: groupClasses },
        Input: { classNames: inputClasses },
        InputWrapper: {
            classNames: inputWrapperClasses,
            defaultProps: {
                inputWrapperOrder: ["label", "input", "description", "error"],
            },
        },
        Menu: { defaultProps: { shadow: "md" } },
        MultiSelect: { classNames: multiSelectClasses },
        Pill: { classNames: pillClasses },
        Text: {
            classNames: textClasses,
            defaultProps: { size: "md" },
        },
        UnstyledButton: { classNames: unstyledButtonClasses },
    },
})
