import { z } from "zod"
import {
    accountWithRecurringBillsSchema,
    accountWithRecurringBillsValidator,
    accountWithoutRecurringBillsSchema,
} from "./RecurringBill"
import {
    accountSchema,
    objectWithEmailsAndPhonesSchema,
    objectWithEncryptedBankAccountDetailsSchema,
} from "./Common"
import { objectWithAddressSchema } from "./Address"
import { PAYMENT_METHODS } from "../../@constants/PaymentMethod"
import { aidString, mediumString, shortMarkdownString } from "./BaseStrings"

/** A supplier without recurring bills.
 * WARNING: this should almost never be used! Use `supplierSchema` instead.
 */
export const baseSupplierSchema = accountSchema
    .merge(objectWithEmailsAndPhonesSchema)
    .merge(objectWithAddressSchema)
    .merge(objectWithEncryptedBankAccountDetailsSchema)
    .extend({
        name: mediumString.optional(),
        /** Default counterpart's aid for new transactions. */
        category: aidString.optional(),
        notes: shortMarkdownString.optional(),
        paymentMethod: z.enum(PAYMENT_METHODS).optional(),
    })

export const supplierSchema = z
    .union([
        baseSupplierSchema.merge(accountWithRecurringBillsSchema),
        baseSupplierSchema.merge(accountWithoutRecurringBillsSchema),
    ])
    .refine(accountWithRecurringBillsValidator)

export type Supplier = z.infer<typeof supplierSchema>
