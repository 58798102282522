import { z } from "zod"
import { aidSchema, dineroStorableSchema } from "./Common"
import { shortMarkdownString, uuidString, veryShortString } from "./BaseStrings"

export const checkSchema = z.object({
    date: z.number(),
    amount: dineroStorableSchema,
    unitAID: aidSchema,
    uuid: uuidString,
    number: veryShortString,
    notes: shortMarkdownString.optional(),
})
export type Check = z.infer<typeof checkSchema>
