import { z } from "zod"
import { zodRegex } from "./zodExtensions"
import { ParsingErrors } from "./parsingErrors"

/** A string used to represent an `aid`. */
export const aidString = zodRegex<`${string}`>(/^\d+\.\d+$|\d+/, {
    message: ParsingErrors.should_be_decimal_positive_number,
    max: 10,
})
/** A string used to represent a user's uid. */
export const uidString = z.string().max(30)
/** A string used to represent a `uuid`. */
export const uuidString = z.string().max(40)
/** A string used to represent a `buildingRef`. */
export const buildingRefString = z.string().min(1).max(30)
/** A string used to represent an email. */
export const emailString = z.string().email().max(100)
/** A string used to represent a phone number. */
export const phoneString = z.string().max(20)

/**
 * A very short string (language, zip code, floor).
 *
 * Max: 10 chars.
 */
export const veryShortString = z.string().max(10)
/**
 * A short string (country, state, number, bank account type).
 *
 * Max: 30 chars.
 */
export const shortString = z.string().max(30)
/**
 * A short string (title, subject, description, name, city, ...).
 *
 * Max: 80 chars.
 */
export const mediumString = z.string().max(80)
/**
 * A long string, such as notes.
 *
 * Max: 2,000 chars.
 */
export const shortMarkdownString = z.string().max(2000)
/**
 * A long string, such as email or post body.
 *
 * Max: 10,000 chars.
 */
export const longMarkdownString = z.string().max(10000)
/**
 * A very long html string.
 *
 * Max: 260,000 chars.
 */
export const veryLongHtmlString = z.string().max(260000)
/**
 * A very long string, such as email or post body.
 *
 * Max: 260,000 chars.
 */
export const veryLongMarkdownString = z.string().max(260000)
