/**************************************
 * CACHE REDUCERS
 **************************************/

import { PayloadAction } from "@reduxjs/toolkit"
import { CollectionAndObjectList } from "@appnflat-types/schemas/Collection"
import set from "lodash/set"
import cloneDeep from "lodash/cloneDeep"
import { IdForCollectionInput, idForCollection } from "@shared/idForCollection"
import { User } from "@appnflat-types/schemas/User"
import { Building } from "@appnflat-types/schemas/Building"
import { Cache, mergeEntry, initialState, CacheEntry, WebCacheCollections } from "./cacheHelpers"

/** Removes all data from the cache related to a given fiscal year. */
export function _emptyOfFiscalYearData(
    state: Cache,
    action: PayloadAction<{ fiscalYear: number }>
): Cache {
    function removeEntriesOfYear<C extends WebCacheCollections>(collection: C): Cache[C] {
        const newEntries: Record<string, CacheEntry<C>> = {}
        const entries = Object.entries(state[collection])
        for (const [key, entry] of entries) {
            const mergedEntry = mergeEntry<C>(entry)
            if (
                !("fiscalYear" in mergedEntry) ||
                mergedEntry.fiscalYear !== action.payload.fiscalYear
            ) {
                newEntries[key] = cloneDeep(entry)
            }
        }
        return newEntries as Cache[C]
    }
    return {
        banks: removeEntriesOfYear("banks"),
        buildings: state.buildings,
        budgets: removeEntriesOfYear("budgets"),
        categories: removeEntriesOfYear("categories"),
        checks: state.checks,
        emailTemplates: state.emailTemplates,
        invitedUsers: state.invitedUsers,
        lockers: removeEntriesOfYear("lockers"),
        logs: state.logs,
        metadata: state.metadata,
        otonomBatches: state.otonomBatches,
        parkings: removeEntriesOfYear("parkings"),
        penalties: state.penalties,
        people: removeEntriesOfYear("people"),
        preparedOtonomFiles: state.preparedOtonomFiles,
        posts: state.posts,
        requestEmails: state.requestEmails,
        requests: state.requests,
        requestTags: state.requestTags,
        suppliers: removeEntriesOfYear("suppliers"),
        transactions: removeEntriesOfYear("transactions"),
        unreconciledTransactions: removeEntriesOfYear("unreconciledTransactions"),
        units: removeEntriesOfYear("units"),
        users: state.users,
    }
}

/** Removes all data from the cache related to a building. */
export function _emptyOfBuildingData(state: Cache): Cache {
    return {
        ...initialState,
        buildings: cloneDeep(state.buildings),
    }
}

/**
 * Updates a field within an object (within the `edits` field). This is untyped and
 * should not be exported. Set typed version at bottom of file.
 */
export function _updateField<C extends WebCacheCollections>(
    state: Cache,
    action: PayloadAction<{
        /** The type of object to update. */
        collection: C
        /** The uuid of the object to update. */
        uuid: string
        /** The fiscal year the object is in. If the object has no fiscal year, this will have no impact. */
        fiscalYear: number
        /** The field to update. */
        field: string
        /** The value to set. */
        value: any
    }>
) {
    // @ts-ignore
    const id = idForCollection({
        collection: action.payload.collection,
        value: { uuid: action.payload.uuid, fiscalYear: action.payload.fiscalYear },
    })
    if (id)
        set(
            state,
            [action.payload.collection, id, "edits", action.payload.field],
            action.payload.value
        )
}

/** Sets the building details for a building. */
export function _setBuildingFromServer(
    state: Cache,
    action: PayloadAction<{
        /** The building itself. */
        building: Building
        /** The doc `buildings/${buildingRef}/users/${userUID}`. */
        user: User
        /** The ref of the building. */
        ref: string
    }>
) {
    const id = idForCollection({ collection: "buildings", value: action.payload.building })
    if (id) {
        set(state, ["buildings", id, "original"], action.payload.building)
        set(state, ["buildings", id, "user"], action.payload.user)
        set(state, ["buildings", id, "ref"], action.payload.ref)
    }
}

/** Sets the `original` field for an object. */
export function _setObjectFromServer(state: Cache, action: PayloadAction<IdForCollectionInput>) {
    const id = idForCollection(action.payload)
    if (id) set(state, [action.payload.collection, id, "original"], action.payload.value)
}

/** Sets a collection. Will just update the `original` field in the entries. */
export function _setCollectionFromServer(
    state: Cache,
    action: PayloadAction<
        CollectionAndObjectList & {
            /** Whether to replace all values for the given collection by the new values. */
            removeAllCurrentValues: boolean
        }
    >
) {
    if (action.payload.removeAllCurrentValues) set(state, action.payload.collection, {})
    for (let i = 0, n = action.payload.values.length; i < n; i++) {
        const value = action.payload.values[i]
        if (!value) continue
        // @ts-ignore
        const id = idForCollection({ collection: action.payload.collection, value })
        if (id) set(state, [action.payload.collection, id, "original"], value)
    }
}
