import { z } from "zod"

/** A locale (i.e., language + region). */
export enum Locale {
    English_CA = "en-CA",
    French_CA = "fr-CA",
}
/** A locale (i.e., language + region). */
export const localeSchema = z.nativeEnum(Locale)

/** A language (i.e., no region). */
export enum Language {
    English = "en",
    French = "fr",
}
/** A language (i.e., no region). */
export const languageSchema = z.nativeEnum(Language)
