import { z } from "zod"
import { Account, accountSchema, dineroStorableSchema } from "./Common"
import { mediumString } from "./BaseStrings"

export const recurringBillSchema = z.object({
    description: mediumString.optional(),
    amount: dineroStorableSchema,
    paymentDay: z.number().int().gte(1).lte(31).optional(),
    counterpart: mediumString,
    billingDay: z.number().int().gte(1).lte(31),
    category: mediumString,
    endDate: z.number().optional(),
    paymentMethod: z.enum(["otonom", "wire-transfer"]).optional(),
})
export type RecurringBill = z.infer<typeof recurringBillSchema>

/** An account with recurring bills.
 *
 * WARNING: When using this schema, ensure you are also using `accountWithRecurringBillsValidator`
 * to validate the data.
 */
export const accountWithRecurringBillsSchema = z.object({
    recurringBills: z.array(recurringBillSchema),
    /** The days in which there are recurring bills to pay or bill. */
    recurringBillsDays: z.array(z.number().int().gte(1).lte(31)),
})
/** An account WITHOUT recurring bills.  */
export const accountWithoutRecurringBillsSchema = z.object({
    recurringBills: z.null().optional(),
    /** The days in which there are recurring bills to pay or bill. */
    recurringBillsDays: z.null().optional(),
})
const localAccountWithRecurringBillsSchema = accountSchema.merge(accountWithRecurringBillsSchema)
export type AccountWithRecurringBills = z.infer<typeof localAccountWithRecurringBillsSchema>

/** Checks that if an account has a recurringBills field, it also has a valid recurringBillsDays field. */
export function accountWithRecurringBillsValidator(account: AccountWithRecurringBills | Account) {
    return (
        !("recurringBills" in account) ||
        account.recurringBills?.every(
            (bill) =>
                account.recurringBillsDays?.includes(bill.billingDay) &&
                (!bill.paymentDay || account.recurringBillsDays.includes(bill.paymentDay))
        )
    )
}
