import { z } from "zod"
import { dineroStorableSchema, objectWithUUIDSchema } from "./Common"
import { ParsingErrors } from "./parsingErrors"
import { mediumString, shortString } from "./BaseStrings"

export const penaltyKindsSchema = z.enum([
    "customPenalty",
    "customFee",
    "defaultLatePaymentFee",
    "defaultInterests",
])
export type PenaltyKind = z.infer<typeof penaltyKindsSchema>

const penaltyBasisSchema = objectWithUUIDSchema.extend({
    name: shortString.optional(),
    description: mediumString.optional(),
    /** The number of days of grace before charging units a penalty fee. */
    gracePeriod: z.number().int().gte(0).optional(),
    /** The aid of the category to which the penalty is added. */
    category: mediumString.optional(),
})

export const penaltyCustomSchema = penaltyBasisSchema.extend({
    kind: penaltyKindsSchema.extract(["customPenalty", "customFee"]),
    amount: dineroStorableSchema.optional(),
})

export const penaltyDefaultLatePaymentFeeSchema = penaltyBasisSchema.extend({
    kind: penaltyKindsSchema.extract(["defaultLatePaymentFee"]),
    amount: dineroStorableSchema.optional(),
    automaticallyApply: z.boolean().optional(),
})

export const penaltyDefaultInterestsSchema = penaltyBasisSchema.extend({
    kind: penaltyKindsSchema.extract(["defaultInterests"]),
    automaticallyApply: z.boolean().optional(),
    /** The annual interest rate. */
    rate: z
        .number()
        .min(0, { message: ParsingErrors.must_be_positive })
        .max(1, { message: ParsingErrors.must_be_less_than_100 })
        .optional()
        .optional(),
})

export const penaltySchema = z.discriminatedUnion("kind", [
    penaltyDefaultInterestsSchema,
    penaltyDefaultLatePaymentFeeSchema,
    penaltyCustomSchema,
])
export type Penalty = z.infer<typeof penaltySchema>
